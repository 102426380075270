import dataTypeCanvasToPrint from "./dataTypeCanvasToPrint";


export default function () {
  return {
    name: 'Canvas de diseños',
    slug: 'canvas',
    scope: 'instance',
    bundles: ['starter'],
    entities: [
      'canvasPrints'
    ],
    permissions: [
      { slug: 'canvasPrints', label: 'Canvas para Impresión' }
    ],
    routesAdmin: {},
    components: {
      'canvasPrints': {
        codedDataTypes: {
          'canvas': dataTypeCanvasToPrint
        }
      }
    },
  }
}
import { IonButton, IonIcon } from "@ionic/react";
import { printOutline } from "ionicons/icons";
import * as contracts from "./contracts";
import * as creditCollections from "./creditCollections";
import dataTypeInvoiceCustomer from "./dataTypeInvoiceCustomer";
import dataTypeInvoiceITaxes from "./dataTypeInvoiceITaxes";
import dataTypeInvoiceItems from "./dataTypeInvoiceItems";
import dataTypePaymentConfig from "./dataTypePaymentConfig";
import { RoutePaymentsVerify } from "./RoutePaymentsVerify";


const columnRightClasses = {
  container: '',
  firstCol: 'md:basis-1/2',
  secondCol: 'md:basis-1/2'
};

const BtnPrintInvoice = ({ doc, entitySlug, isAllowed, color, fill, size }) => (<>
  {isAllowed(entitySlug, ['print', 'instance:print']) ? (
    <IonButton onClick={() => {console.log('print', doc)}} color={color || "medium"} fill={fill || "clear"} size={size || "small"}>
      <IonIcon slot="start" icon={printOutline} className="text-xl"></IonIcon>
      Imprimir
    </IonButton>
  ) : null}
</>);

const FormToolbarEndInvoices = ({ doc, form, values, classes, handleSubmit, submitting, fieldsRequired, instance, entitySlug, isAllowed, history, match }) => (<>
  <BtnPrintInvoice doc={doc} entitySlug={entitySlug} isAllowed={isAllowed} color="primary" fill="clear" />
</>);

const ShowItemExtraActionsInvoices = ({ isAllowed, entitySlug, entityDoc, entityId, docId, doc, attachPrefix }) => {
  return (<>
    <BtnPrintInvoice doc={doc} entitySlug={entitySlug} isAllowed={isAllowed} color="primary" fill="clear" />
  </>);
};

const ListItemExtraActionsInvoices = ({ doc, Model, isAllowed, canAddMoreDocs, entitySlug, entityDoc, location, entitySpecs, moduleSettings, attachPrefix }) => (<>
  <BtnPrintInvoice doc={doc} entitySlug={entitySlug} isAllowed={isAllowed} color="primary" fill="clear" />
</>);

export default function () {
  return {
    name: 'Ventas',
    slug: 'sales',
    scope: 'instance',
    bundles: ['starter'],
    entities: [
      'invoices',
      'payments',
      'creditCollections',
      'invoiceBooks',
      'contracts',
      'contractsModels'
    ],
    permissions: [
      { slug: 'invoices', label: 'Facturas', actionsToAdd: ['tab:sales', 'print']},
      { slug: 'payments', label: 'Pagos' },
      { slug: 'creditCollections', label: 'Cobros de créditos' },
      { slug: 'invoiceBooks', label: 'Talonarios de facturas' },
      { slug: 'contracts', label: 'Contrataciones' },
      { slug: 'contractsModels', label: 'Modelos de contratos' },
    ],
    routesAdmin: {
      'payments': {
        'verify': {
          permission: { resource: 'instance.payments', action: ['update'] },
          Content: RoutePaymentsVerify
        }
      },
    },
    components: {
      'invoices': {
        crud: {
          ListItemExtraActions: ListItemExtraActionsInvoices,
          FormToolbarEnd: FormToolbarEndInvoices,
          ShowItemExtraActions: ShowItemExtraActionsInvoices
        },
        codedDataTypes: {
          'customer': dataTypeInvoiceCustomer,
          'items': dataTypeInvoiceItems,
          'taxes': dataTypeInvoiceITaxes,
        }
      },
      'contracts': {
        crud: {
          settings: () => ({
            columnRightTaxonomyTypesSlugs: null,
          }),
          'FormAppendColumnRight': {
            classes: columnRightClasses,
            Render: contracts.FormAppendColumnRight
          },
          'FormAppend': contracts.FormAppend,
          'FormAppend-modelId': contracts.ContractModelForeignForm
        }
      },
      'contractsModels': {
        codedDataTypes: {
          'paymentConfig': dataTypePaymentConfig
        }
      },
      'creditCollections': {
        crud: {
          'FormAppendColumnRight': {
            classes: columnRightClasses,
            Render: creditCollections.FormAppendColumnRight
          },
          'FormPrepend-paidDate': creditCollections.FormPrependSelector,
          'FormAppend-paidDate': creditCollections.FormAppendRelatedData
        }
      }
    },
    events: {
      'contracts': {
        afterRead: contracts.afterRead,
        afterSave: contracts.afterSave
      },
      'creditCollections': {
        afterRead: creditCollections.afterRead,
        beforeSave: creditCollections.beforeSave,
        afterSave: creditCollections.afterSave
      }
    }
  }
}
import _ from 'lodash';

////// import to initialize correctly
import '../components/DataTypes';
import '../components/Form';
import '../components/Filters';

import panel from './panel';
import entity from './entity';
import user from './user';
import instance from './instance';
import blockStack from './blockStack';
import layoutHome from './layoutHome';
import secretary from './secretary';
import cart from './cart';
// import message from './message';
// import virtualAgents from './virtualAgents';
// import accountManagers from '../modules/accountManagers';
import apiKey from './apiKey';
import customModules from '../customModules';
import usersSections from './usersSections';
import sales from './sales';
import contacts from './contacts';
import pagesTemplates from './pagesTemplates';
import canvas from './canvas';


const modules = [
  panel,
  entity,
  user,
  instance,
  blockStack,
  pagesTemplates,
  layoutHome,
  secretary,
  cart,
  // message,
  apiKey,
  usersSections,
  sales,
  contacts,
  canvas,
  // virtualAgents,
  // accountManagers
  ...customModules
];
/**
 * Module Structure {
 *    // Set Permissions for data and sections
 *    // Custom component parts
 * 
 *    slug,
 *    label,
 *    scope: string, // 'global' / 'instance'
 * 
 *    entities: {
 *      [slug]: {
 *        entity: { id, nameSlug, ... },
 *        filterMenuList: [ { id, entityId, nameSlug, filters: [ taxonomyTypeId ] ... },  ...],
 *        taxonomyTypesList: [ { id, entityId, type, nameSlug, ... },  ...],
 *      }
 *    },
 * 
 *    permissions: {
 *      [resourceSlug]: [{ slug, label, actionsToAdd, actions }]
 *    },
 * 
 *    routesPublic,
 *    routesAdmin: {
 *      [slug]: {
 *        [action]: {
 *          permission: { resource, action },
 *          Content // param { 
 *            parsedParams, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history,
 *            // panel
 *            versionCurrent, settings, isUserMenuPopoverOpen, setIsUserMenuPopoverOpen, selectedInstance, setSelectedInstance
 *          }
 *        }
 *      }
 *    },
 * 
 *    components: {
 *      main: {
 *        CustomRoutes, // param { Route, ProtectedRoute }
 *        LayoutAdminUnderContent, // param { isAllowed, user, ...moduleLibs }
 *        SideMenuUnderCurrentUser, // param { user, history, triggerId, openMenu, closeMenu, ...moduleLibs }
 *        SideMenuUnderSectionsList, // param { user, history, triggerId, openMenu, closeMenu, ...moduleLibs }
 *        SideMenuBeforeVersion, // param { user, history, triggerId, openMenu, closeMenu, ...moduleLibs }
 *        HeaderToolbarLeft, // param { isAllowed, user, ...moduleLibs }
 *        HeaderToolbarRight, // param { isAllowed, user, ...moduleLibs }
 *        AdminHomeBeforeLinksList, // param { isAllowed, user }
 *        AdminHomeAfterLinksList, // param { isAllowed, user }
 *        AdminConfigBeforeLinksList, // param { isAllowed }
 *        AdminConfigAfterLinksList, // param { isAllowed }
 *        AdminEntityBeforeLinksList, // param { isAllowed }
 *        AdminEntityAfterLinksList, // param { isAllowed }
 *      },
 * 
 *      [slug]: {
 *    
 *        // Custom DataType for Entity, require 'coded' as the taxonomyType
 *        codedDataTypes: {
 *          [entityTaxonomyType.nameSlug]: {
 *            RenderForm, // param { ...props, value, onChange, title, taxonomyTypeData, param }
 *            RenderShow, // param { displayedValue, doc, field, entitySlug, taxonomyTypeData }
 *            RenderShowList // optional, param { displayedValue, doc, field, entitySlug, taxonomyTypeData }
 *          }
 *        },
 * 
 *        crud: {
 *          settings: () => ({
 *             // for show SectionCrudModel
 *             title: false, // string
 *             reorder: false,
 *             sortDocs: null, // FN (docs) => reorderedDocs
 *             showBtnAdd: true,
 *             showBtnShow: true,
 *             showBtnDelete: true,
 *             showBtnUpdate: true,
 *             showBtnMove: true,
 *             addButtonPermissionAction: 'create',
 *             addButtonLabel: '+ Agregar',
 *             // for form
 *             redirectOnSave: true
 *          }), 
 * 
 *          ListFilterCustom, // param { Model, isAllowed }
 *          ListHeaderExtraActions, // param { Model, isAllowed, canAddMoreDocs, entitySlug, entityDoc, location, entitySpecs, moduleSettings, attachPrefix }
 *          ListItemExtraActions, // param { doc, Model, isAllowed, canAddMoreDocs, entitySlug, entityDoc, location, entitySpecs, moduleSettings, attachPrefix }
 *          UnderListItemTitle, // param { doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }
 *          UnderListItemHeader, // param { doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }
 *          
 *          FormPrepend, // param { values, doc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed, history, match, formApi, fieldsRequired, fieldsRequiredMap, setFieldsRequiredMap }
 *          FormAppend, // param { values, doc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed, history, match, formApi, fieldsRequired, fieldsRequiredMap, setFieldsRequiredMap }
 *          FormAppendColumnRight, // param { values, doc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed, history, match, formApi }
 *          FormPrepend-{entitySlug}, // param { form, values, doc, instance, entityDoc, entitySlug, taxonomyTypeDoc, taxonomyTypesDocList, isAllowed, history, match, fieldsRequired, fieldsRequiredMap, setFieldsRequiredMap }
 *          FormAppend-{entitySlug}, // param { form, values, doc, instance, entityDoc, entitySlug, taxonomyTypeDoc, taxonomyTypesDocList, isAllowed, history, match, fieldsRequired, fieldsRequiredMap, setFieldsRequiredMap }
 *          FormToolbarEnd, // param { doc, form, values, classes, handleSubmit, submitting, fieldsRequired, instance, entitySlug, isAllowed, history, match }
 *          UnderForm, // param { doc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed, history, match }
 * 
 *          ShowItemExtraActions // param { isAllowed, entitySlug, entityDoc, entityId, docId, doc, attachPrefix }
 *        }
 *        
 *      }
 *    },
 * 
 *   events: {
 *     [slug]: {
 *       afterRead, 
 *         // param { doc, isEdit, instance. entitySlug, taxonomyTypesDocList, instanceDoc, entityDoc }, docData
 *         // return modifiedDocData
 *       beforeSave, 
 *         // param { doc, isEdit, instance. entitySlug, taxonomyTypesDocList, instanceDoc, entityDoc }, formValues
 *         // return formValues
 *       afterSave, 
 *         // param { doc, formValues, modifiedFormValues, instanceDoc, isEdit, entitySlug, taxonomyTypesDocList }, savedDoc
 *         // return undefined
 *       beforeDelete, 
 *         // param { doc, isEdit, instance. entitySlug, taxonomyTypesDocList, instanceDoc, entityDoc }, formValues
 *         // return formValues
 *     }
 *   }
 * 
 * }
 */

export const boot = () => {
  let actionsByResource = {};

  const modulesBooted = modules.map((moduleBoot) => {
    const moduleDef = moduleBoot({ setPermissions });
    _.defaults(moduleDef, {
      scope: ['global']
    });
    // map actions by resource for permissions
    moduleDef?.permissions?.forEach(permissionsDef => {
      let permissionsMap = setPermissions(permissionsDef);
      permissionsDef.actions = permissionsMap.actions;
      actionsByResource[permissionsMap?.resource] = permissionsMap;
      actionsByResource[permissionsMap?.resource].module = moduleDef;
    });
    return moduleDef;
  });
  return {
    modules: modulesBooted,
    actionsByResource
  }
};

export const setPermissions = ({ slug, label, actionsToAdd=[], actions, instanceCrud, ownerCrud }) => {
  let permissions = {
    resource: slug,
    label,
    actions: actions || [
      'section',
      'read',
      'list',
      'create',
      'update',
      'delete',
      ...actionsToAdd
    ]
  };
  if (instanceCrud) {
    permissions.actions = permissions.actions.concat([
      'instance:section',
      'instance:read',
      'instance:list',
      'instance:create',
      'instance:update',
      'instance:delete'
    ]);
  }
  if (ownerCrud) {
    permissions.actions = permissions.actions.concat([
      'owner:read',
      'owner:list',
      'owner:create',
      'owner:update',
      'owner:delete'
    ]);
  }
  return permissions;
}
import _ from "lodash";
import { useMemo } from "react";
import { useAsyncMemo } from 'use-async-memo';
import { useCart } from "./useCartData";
import Model from "../../libs/ModelClass";
import { withPrefix } from "../instance/utilsInstance";
import { getConfig } from "../../config";


export const useCategoryFields = (props) => {
  const { entityMap } = useCart();

  const categoryFieldsDocLists = useAsyncMemo(async () => {
    if (props.categoryFieldsDocLists) {
      return props.categoryFieldsDocLists;
    }
    let docs = await Model.extend(entityMap.cartItemFields?.entitySlug || withPrefix(props.instance, getConfig().modules.cart.cartItemFieldsEntitySlug))
      .filterByAttributes({ deleted: 'false' });
    docs.forEach((doc) => {
      // data to doc format
      doc.data.fields = doc.data.fields.map((data) => (new Model(data)));
    });
    return docs;
  }, []);

  const categoryFieldsById = useMemo(() => {
    if (props.categoryFieldsById) {
      return props.categoryFieldsById;
    }
    if (!categoryFieldsDocLists) { return null; }
    let categoryFieldsById = {};
    categoryFieldsDocLists.forEach((doc) => {
      categoryFieldsById[doc.id] = doc;
    });
    return categoryFieldsById;
  }, [categoryFieldsDocLists]);

  const getFieldsDocsByIdsList = (fieldsIds, taxonomyTypesDocsList = []) => {
    if (!fieldsIds || !categoryFieldsById) { return []; }
    let fieldsDocs = _.filter(categoryFieldsDocLists, (fieldsDoc) => {
      return _.find(fieldsIds, (id) => (id === fieldsDoc.id));
    });
    if (fieldsDocs) {
      fieldsDocs.forEach((fieldDoc) => {
        let taxonomyTypesDocs = fieldDoc.data.fields; // parsed as Model instance on useCategoryFields
        _.forEach(taxonomyTypesDocs, (taxonomyType) => {
          if (taxonomyType?.data?.isFilter) {
            let doc = new Model(taxonomyType.data); // to be used for lists, views and forms
            doc.taxonomyType = taxonomyType.data; // to be used for filters
            taxonomyTypesDocsList.push(doc);
          }
        });
      });
    }
    return taxonomyTypesDocsList;
  };

  return {
    categoryFieldsDocLists,
    categoryFieldsById,
    getFieldsDocsByIdsList
  };
}
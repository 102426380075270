import { Field } from 'react-final-form';
import _ from 'lodash';

import { FieldLabel, ShowFieldHelp } from './formParts';

const FormField = (props) => {
  let {
    name,
    title,
    placeholder,
    fieldsRequired = null,
    type,
    defaultValue,
    // showLabel = true,
    // showClearBtn = true,
    validate = null,
    disabled = false,
    formatter = (value) => value,
    minValue = null,
    maxValue = null,
    minLength = null,
    maxLength = null,
    UnderInput = null,
    onChange = null,
    onBlur = null,
    onAfterChange,
    formApi
  } = props;
  
  const validateField = (value) => {
    let error;
  
    if (value !== undefined) {
      if (type === 'number') {
        const numericValue = parseFloat(value);
  
        if (minValue && numericValue < parseFloat(minValue)) {
          error = `El valor debe ser mayor o igual a ${minValue}`;
        } else if (maxValue && numericValue > parseFloat(maxValue)) {
          error = `El valor debe ser menor o igual a ${maxValue}`;
        }
      } else if (type === 'text') {
        if (minLength && value?.length < parseInt(minLength)) {
          error = `El valor debe tener al menos ${minLength} caracteres`;
        } else if (maxLength && value?.length > parseInt(maxLength)) {
          error = `El valor debe tener como máximo ${maxLength} caracteres`;
        }
      }
    }
  
    if (!error && validate && typeof validate === 'function') {
      error = validate(value);
    }
  
    return error;
  };

  const getDefaultValue = () => {
    if (type === 'text') {
      if (!_.isString(defaultValue)) {
        return '';
      }
      return defaultValue || '';
    } 
    else if (type === 'number') {
      if (!_.isNumber(defaultValue)) {
        return 0;
      }
      return defaultValue || null;
    }
    else if (type === 'boolean') {
      if (!_.isBoolean(defaultValue)) {
        return false;
      }
      return defaultValue || false;
    }    
  };

  return (
    <div className={`${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={validateField} defaultValue={getDefaultValue()}>
        {({ input, meta }) => (
          <>
            <FieldLabel {...props} input={input} meta={meta} />
            <input
              className={`border-gray-200 border h-[40px] px-2 py-1.5 rounded-md ${props?.classes?.fieldInput || ''}`}
              type={type}
              placeholder={placeholder || title}
              value={input.value}
              onChange={async (e) => {
                let formatedValue;
                if (formatter) {
                  formatedValue = formatter(e.target.value);
                }
                else if (type === 'number') {
                  formatedValue = _.toNumber(e.target.value);
                }
                input.onChange(formatedValue); 
                onChange && onChange(formatedValue);
                onAfterChange && await onAfterChange({ value: formatedValue, formApi });
              }}
              onBlur={(e) => {input.onBlur(e); onBlur && onBlur(e)}}
              disabled={disabled}
              min={type === 'number' && _.isNumber(minValue) ? parseFloat(minValue) : undefined}
              max={type === 'number' && _.isNumber(maxValue) ? parseFloat(maxValue) : undefined}
              minLength={type === 'text' && minLength ? parseInt(minLength) : undefined}
              maxLength={type === 'text' && maxLength ? parseInt(maxLength) : undefined}
            />
            {UnderInput && (<UnderInput input={input} />)}
            {/* error */}
            <ShowFieldHelp {...props} name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
          </>
        )}
      </Field>
    </div>
  );
};

export default FormField;
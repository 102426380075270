import React, { useState, useEffect, Fragment, useMemo } from 'react';
import _ from 'lodash';
import toast from 'react-hot-toast';
import SectionCrudForm from './SectionCrudForm';
import { withRouter } from 'react-router-dom';
import { IonButton, IonButtons, IonCard, IonCardContent, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonList, IonModal, IonReorder, IonReorderGroup, IonTitle, IonToolbar } from '@ionic/react';
import ModalConfirmDelete from '../modules/entity/ModalConfirmDelete';
import { getUrlWithParams, sortDocsByField } from '../libs/utils';
import { useFilterPagination } from '../modules/entity/useFilterPagination';
import EntityFilterMenuDisplay from './EntityFilterMenuDisplay';
import { useScrollUtils } from '../modules/panel/useScrollUtils';
import { useModule } from '../libs/ModuleContext';
import { addOutline, createOutline, trashBinOutline } from 'ionicons/icons';


// En SectionListGrid absatrae la lógica de renderizado del listado
// separando en dos modos: 'dragable' y 'list'

// Ahora implementa el modo: 'dragable'
// SectionListDragDrop sólo funciona con el modo 'all'
// utiliza IonReorderGroup

// Ahora implementa el modo: 'filter-paginated'
// utiliza useFilterPagination
// adapta SectionListGrid para utilizar pagesData[currentPage]

export const SectionListFilterPaginated = (props) => {
  let { queryParams, filterPath, sectionId, processFilter, entitySpecs, paginationLibs, history, only, exclude } = props;
  let { pagesData, isLoadingWithFilters, totalPages, isEmptyResults, nextPage, currentPage, setPage } = paginationLibs;
  const { scrollTo } = useScrollUtils();
  
  const onFormClear = () => {
    history.push(filterPath);
  };

  const onFormChange = (formValues) => {
    console.log('onFormChange', formValues)
    if (processFilter) {
      processFilter({ queryParams: formValues, totalPages });
    }
    else if (formValues) {
      const url = getUrlWithParams(filterPath, formValues);
      history.push(url);
      scrollTo(sectionId);
    }
  };

  return (
    <div className={`flex flex-col lg:flex-row`}>
      <div className="lg:basis-4/12">
        <EntityFilterMenuDisplay
          {...entitySpecs}
          style={'search-collapsible-drawer'}
          filterData={queryParams}
          defaultPath={filterPath}
          onFormClear={onFormClear}
          onFormChange={onFormChange}
        />
      </div>
      <div className={`w-full !p-2 md:!p-4`}>
        {(pagesData?.length && pagesData[0].length) ? (
          <IonCard>
            <IonCardContent className="px-0">
              {pagesData.map((pageData, index) => (
                <Fragment key={index}>
                  <ListGrid {...props} entitySlug={entitySpecs.entitySlug} docs={pageData} showEmptyMessage={false} {...{only, exclude}} />
                </Fragment>
              ))}
            </IonCardContent>
          </IonCard>
        ) : null}
        
        {!isLoadingWithFilters && isEmptyResults ? (
          <div className="my-12 text-center">
            No hay resultados
          </div>
        ) : null}

        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            nextPage();
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        {/* <IonContent>
        </IonContent> */}
        {/* {isLoadingWithFilters ? (
          <div className="py-12 flex place-content-center content-center items-center font-brand-main">
            <BadgeLoading className="text-brand-dark" />
          </div>
        ) : null} */}
        {/* {!isLoadingWithFilters && !isEmptyResults ? (
          totalPages && pagesData?.length ? (
            <ReactVisibilitySensor
              //minTopValue={500} // Ajusta esta distancia según tu necesidad
              partialVisibility={true}
              offset={{ bottom: -100 }}
              delayedCall={false} // Activa el sensor tan pronto como se alcance la distancia mínima
              onChange={nextPage}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setActive={setPage}
                classes={{ paginationContainer: 'mt-8' }}
              />
            </ReactVisibilitySensor>          
          ) : null
        ) : null} */}
      </div>
    </div>
  );
};

export const SectionListDragDrop = ({ entitySlug, docs, handleDragEnd, ListItem, ListBtns, handleEdit, handleDelete, ItemExtraActions, isAllowed, showBtnUpdate, showBtnDelete, showBtnMove, showBtnCrud, only, exclude }) => (
  docs?.length ? (
    <IonList className="py-0 !m-2 md:!m-4" inset={true}>
      <IonReorderGroup disabled={!showBtnMove} onIonItemReorder={handleDragEnd}>
        {docs.map((doc, index) => (
          <IonItem key={doc.id}>
            <div className="w-full">
              <div className="mb-0.5 mt-2">
                <ListItem doc={doc} {...{only, exclude}} />
              </div>
              <IonButtons>
                {(isAllowed(entitySlug, ['update', 'instance:update']) && showBtnCrud && showBtnMove) && (
                  <IonReorder />
                )}
                {/* custom btns */}
                <ListBtns doc={doc} />
                {/* common actions */}
                {(isAllowed(entitySlug, ['update', 'instance:update']) && showBtnCrud && showBtnUpdate) && (
                  <IonButton color="medium" size="small" fill="clear" onClick={() => handleEdit(doc)}>
                    <IonIcon slot="start" icon={createOutline}></IonIcon>
                    Editar
                  </IonButton>
                )}
                {(isAllowed(entitySlug, ['delete', 'instance:delete']) && showBtnCrud && showBtnDelete) && (
                  <IonButton color="medium" size="small" fill="clear" onClick={() => handleDelete(doc)}>
                    <IonIcon slot="start" icon={trashBinOutline}></IonIcon>
                    Eliminar
                  </IonButton>
                )}
                {ItemExtraActions && <ItemExtraActions doc={doc} isAllowed={isAllowed} />}
              </IonButtons>
            </div>
          </IonItem>
        ))}
      </IonReorderGroup>
    </IonList>
  ) : null
);

export const ListGrid = ({ entitySlug, showEmptyMessage, EmptyContent, docs, ListItem, ListBtns, handleEdit, handleDelete, ItemExtraActions, isAllowed, showBtnUpdate, showBtnDelete, showBtnCrud, only, exclude }) => {
  return (<>
    {docs?.length ? (
      <IonList className="py-0 !mx-0 !my-0" lines="full" inset={true}>
        {docs.map(doc => (
          <IonItem key={doc.id}>
            <div className="w-full pt-2.5 pb-2">
              <div className={(showBtnCrud) ? 'mb-0.5' : ''}>
                <ListItem doc={doc} {...{only, exclude}} />
              </div>
              <div className="relative">
                <ListBtns doc={doc} />
                {(isAllowed(entitySlug, ['update', 'instance:update']) && showBtnCrud && showBtnUpdate) && (
                  <IonButton color="medium" size="small" fill="clear" onClick={() => handleEdit(doc)}>
                    <IonIcon slot="start" icon={createOutline}></IonIcon>
                    Editar
                  </IonButton>
                )}
                {(isAllowed(entitySlug, ['delete', 'instance:delete']) && showBtnCrud && showBtnDelete) && (
                  <IonButton color="medium" size="small" fill="clear" onClick={() => handleDelete(doc)}>
                    <IonIcon slot="start" icon={trashBinOutline}></IonIcon>
                    Eliminar
                  </IonButton>
                )}
                {ItemExtraActions && <ItemExtraActions doc={doc} isAllowed={isAllowed} />}
              </div>
            </div>
          </IonItem>
        ))}
      </IonList>
    ) : null}
    {docs && !docs.length ? (<>
      {showEmptyMessage && !EmptyContent ? (
        <div className="my-12 text-center">
          No hay resultados
        </div>
      ) : null}
      {EmptyContent ? (
        <EmptyContent />
      ) : null}
    </>
    ) : null}
  </>);
};


export const SectionListGrid = (props) => {
  return (
    <IonCard className="m-0">
      <IonCardContent className="p-0">
        <ListGrid {...props} />
      </IonCardContent>
    </IonCard>
  );
};

export const fetchData = async ({ model, reorder, sortDocs, hardFilter, softFilter, setDocs, onFetch }) => {
  let query = {};
  if (hardFilter) {
    query = await hardFilter(query);
  }
  let docs = await model.filterByAttributes(query);
  docs = docs.filter(doc => !doc.data?.deleted);

  if (reorder) {
    sortDocsByField(docs, 'sort');
  } else {
    if (sortDocs) {
      docs = sortDocs(docs);
    } else {
      sortDocsByField(docs, 'createdAt DESC', 'dateString');
    }
  }

  if (softFilter) {
    docs = softFilter(docs);
  }

  setDocs(docs);
  onFetch && onFetch(docs);
};

// En SectionCrudModel absatrae la lógica de gestión de los datos del listado
// separando en dos modos: 'all' y 'filter-paginated'
// Por ahora ignora el modo 'filter-paginated', lo implementaremos en otro momento
// Implementa el modo: 'all'

// Ahora implementa el modo: 'filter-paginated'
// utiliza useFilterPagination
// adapta SectionListGrid para utilizar pagesData[currentPage]
// SectionListDragDrop sólo funciona con el modo 'all'


function SectionCrudModel(props) {
  let {
    history,
    refresh,
    refreshers = [],
    model,
    entitySlug,
    editStyle,
    dataMode = 'all', // 'all' or 'filter-paginated'
    listStyle = 'dragable',
    title,
    subtitle,

    // show
    showToolbar = true,
    showToast = true,
    showBtnCrud = true,
    showBtnAdd = true,
    showBtnUpdate = true,
    showBtnDelete = true,
    showBtnMove = true,
    showEmptyMessage = true,

    // fields
    fieldsRequired,
    only,
    exclude,

    // callbacks
    onValidation = (() => null),
    fetchItems = null,
    sortDocs = null,
    handleBeforeSave = (() => null),
    handleBeforeDelete = (() => null),
    hardFilter = null,
    softFilter = null,
    onDelete = (() => null),
    onFetch = (() => null),
    handleActions = null,
    navigateTo = ((viewType, doc) => null),
    processFilter = null,
    navigateToClose = null,
    onRefresh = null,

    // UI
    ListItem,
    ExtraActions,
    ItemExtraActions,
    ListBtns = (() => null),
    FormSection = null,
    FormInputFields = null,
    EmptyContent = null,

    // actions
    addButtonLabel = 'Agregar',

    // dataMode 'filter-paginated'
    entitySpecs,
    filterPath,
    queryParams
  } = props;

  const sectionId = useMemo(() => (_.uniqueId('section')), []);
  const [ docs, setDocs ] = useState();
  const [ selectedDoc, setSelectedDoc ] = useState(null);
  const [ openSelector, setOpenSelector ] = useState(false);
  const { isAllowed } = useModule();
  const [ refreshCount, setRefreshCount ] = useState(0);
  const [ selectedDocToDelete, setSelectedDocToDelete ] = useState(null);

  const reorder = listStyle === 'dragable';
  const FormSectionToUse = FormSection || SectionCrudForm;

  const paginationLibs = useFilterPagination({
    entitySpecs,
    queryParams,
    hardFilter,
    isEnabled: dataMode === 'filter-paginated'
  });

  let { pagesData, currentPage, deleteDocFromPages } = paginationLibs;

  let ListComponent;
  if (listStyle === 'dragable') {
    ListComponent = SectionListDragDrop
  } 
  else if (listStyle === 'list') {
    ListComponent = SectionListGrid;
  }
  else if (listStyle === 'filter-paginated') {
    ListComponent = SectionListFilterPaginated;
  }

  const refreshList = async () => {
    let props = {
      model,
      reorder,
      sortDocs,
      hardFilter,
      softFilter,
      setDocs,
      onFetch
    };

    // FETCH
    if (fetchItems) {
      await fetchItems(props);
    } else {
      await fetchData(props);
    }
  };

  useEffect(() => {
    if (dataMode === 'all' || fetchItems) {
      refreshList();
    }
  }, [refreshCount, refresh, dataMode, ...refreshers]);

  useEffect(() => {
    onRefresh && onRefresh();
  }, [refreshCount]);

  // modes comprobations
  if (listStyle === 'dragable' && dataMode !== 'all') {
    throw new Error('listStyle "dragable" only works with dataMode "all"');
  }

  if (listStyle !== 'filter-paginated' && dataMode === 'filter-paginated') {
    throw new Error('dataMode "filter-paginated" only works with listStyle "filter-paginated"');
  }

  const handleSave = async (docData) => {
    if (reorder) {
      docData.sort = (docData.sort >= 0) ? docData.sort : docs?.length;
    }
    await handleBeforeSave(docData);
    await model.createOrUpdate(docData);
    setRefreshCount(refreshCount + 1);
    setOpenSelector(false);
    setSelectedDoc(null);
  };

  const handleEdit = (doc) => {
    setSelectedDoc(doc);
    if (handleActions) {
      handleActions('form', doc);
    }
    else if (editStyle === 'modal' || editStyle === 'onsite') {
      setOpenSelector(true);
    }
    else if (editStyle === 'route') {
      history.push(navigateTo('form', doc));
    }
  };

  const handleAdd = () => {
    if (handleActions) {
      handleActions('form');
    }
    else if (editStyle === 'modal' || editStyle === 'onsite') {
      setSelectedDoc({});
      setOpenSelector(true);
    } 
    else if (editStyle === 'route') {
      setSelectedDoc({});
      history.push(navigateTo('form'));
    }
  };

  const handleClose = () => {
    setOpenSelector(false);
    setSelectedDoc(null);
  };

  const handleDragEnd = async (event) => {
    if (!event.detail.from) return;
    const newOrder = Array.from(docs);
    const [movedItem] = newOrder.splice(event.detail.from, 1);
    newOrder.splice(event.detail.to, 0, movedItem);
    if (reorder) {
      setDocs(newOrder);
      await model.saveSort(newOrder);
      showToast && toast.success('Se ha actualizado el orden');
    }
    event.detail.complete();
  };

  const renderListComponent = () => {
    if (dataMode === 'all') {
      return (
        <ListComponent
          entitySlug={entitySlug}
          docs={docs}
          handleDragEnd={handleDragEnd}
          ListItem={ListItem}
          ListBtns={ListBtns}
          queryParams={queryParams}
          processFilter={processFilter}
          handleEdit={handleEdit}
          handleDelete={setSelectedDocToDelete}
          ItemExtraActions={ItemExtraActions}
          reorder={reorder}
          isAllowed={isAllowed}
          showBtnCrud={showBtnCrud}
          showBtnUpdate={showBtnUpdate}
          showBtnDelete={showBtnDelete}
          showBtnMove={showBtnMove}
          showEmptyMessage={showEmptyMessage}
          EmptyContent={EmptyContent}
          history={history}
          sectionId={sectionId}
          only={only}
          exclude={exclude}
        />
      );
    } else if (dataMode === 'filter-paginated') {
      return (
        <ListComponent
          filterPath={filterPath}
          docs={pagesData ? pagesData[currentPage - 1] : []}
          entitySpecs={entitySpecs}
          paginationLibs={paginationLibs}
          ListItem={ListItem}
          ListBtns={ListBtns}
          queryParams={queryParams}
          processFilter={processFilter}
          handleEdit={handleEdit}
          handleDelete={setSelectedDocToDelete}
          ItemExtraActions={ItemExtraActions}
          isAllowed={isAllowed}
          showBtnCrud={showBtnCrud}
          showBtnUpdate={showBtnUpdate}
          showBtnDelete={showBtnDelete}
          showBtnMove={showBtnMove}
          showEmptyMessage={showEmptyMessage}
          EmptyContent={EmptyContent}
          history={history}
          sectionId={sectionId}
          only={only}
          exclude={exclude}
        />
      );
    }
  };

  return (
    <>
      {showToolbar ? (
        <IonHeader size="small" className="sticky top-0">
          <IonToolbar className="px-2 md:px-4">
            {/* titles */}
            {(title || subtitle) ? (
              <div slot="start">
                {title && <IonTitle size="small" color="dark" className="px-0 pr-4 text-xs uppercase font-bold">{title}</IonTitle>}
                {subtitle && <IonTitle size="small" color="medium" className="px-0 pr-4 text-sm">{subtitle}</IonTitle>}
              </div>
            ) : null}
            {/* add btn */}
            <IonButtons slot="start">
              {(isAllowed(entitySlug, ['create', 'instance:create']) && showBtnCrud && showBtnAdd) && (
                <IonButton size="small" fill="solid" color="primary" onClick={handleAdd}>
                  <IonIcon icon={addOutline} slot="start" />
                  {addButtonLabel}
                </IonButton>
              )}
            </IonButtons>
            {/* extra actions */}
            {ExtraActions && <ExtraActions {...{ refreshList, toast }} />}
          </IonToolbar>
        </IonHeader>
      ) : null}
      
      {/* form */}
      {openSelector && selectedDoc && (
        <FormSectionToUse
          entityDoc={entitySpecs?.entityDoc}
          model={model}
          editStyle="modal"
          doc={selectedDoc}
          onSave={handleSave}
          onClose={handleClose}
          fieldsRequired={fieldsRequired}
          onValidation={onValidation}
          FormInputFields={FormInputFields}
          showToast={showToast}
        />
      )}
      {renderListComponent()}
      
      <ModalConfirmDelete
        entitySlug={entitySlug}
        selectedDoc={selectedDocToDelete}
        afterDelete={() => {
          setRefreshCount(refreshCount + 1);
          if (dataMode === 'filter-paginated') {
            deleteDocFromPages(selectedDocToDelete);
          }
          setSelectedDocToDelete(null);
        }}
        onCancel={() => setSelectedDocToDelete(null)}
      />
    </>
  );
}

export default withRouter(SectionCrudModel);

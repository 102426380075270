import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { compileStringTemplate, lineBreaks } from "../../../libs/utils";
import { Background } from "../parts/PartBackground";
import { Text } from "../parts/PartText";
import { Card } from "../parts/PartCard";
import NotionShowLazy from "../../../components/ui/NotionShowLazy";
import { Image } from "../parts/PartImage";
import { cardContainerStylesField } from "../parts/stylesFields";
import { CardContainer } from "../parts/PartCardContainer";
import { BtnsList, sectionCardBtnsFields } from "./BlockSectionCardBtns";


export default function () {
  return {
    type: 'textGrid',
    variant: 'extended',
    title: 'Extendido',
    template: TextGridAExtended,
    presets,
    params: {
      'bgStyles': {
        name: 'Fondo',
        type: 'part',
        part: 'background'
      },
      'cardContainerStyles': cardContainerStylesField,

      'itemCardStyles': {
        name: 'Tarjeta de cada item',
        type: 'part',
        part: 'card'
      },
      'imgCardStyles': {
        name: 'Items: Tarjeta de la imagen',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'titleStyles': {
        name: 'Título',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'titleCardStyles': {
        name: 'Tarjeta del Título',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'subtitleStyles': {
        name: 'Subtítulo',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'contentTextStyles': {
        name: 'Contenido',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'contentStyles': {
        name: 'Contenido',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'subtitleCardStyles': {
        name: 'Tarjeta del Subtítulo',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'contentTextCardStyles': {
        name: 'Tarjeta del Contenido',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'contentCardStyles': {
        name: 'Tarjeta del Contenido',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'btnListStyles': {
        name: 'Botones',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      }
    },
    // fields of text tab
    strings: {
      'items': {
        type: 'collection',
        name: 'items',
        fields: {
          'img': {
            name: 'Imagen',
            type: 'part',
            part: 'image'
          },
          'title': {
            type: 'textArea',
            name: 'Título'
          },
          'subtitle': {
            type: 'textArea',
            name: 'Subtítulo'
          },
          'contentText': {
            type: 'textArea',
            name: 'Contenido'
          },
          'content': {
            type: 'textArea',
            design: 'notion',
            name: 'Contenido Notion'
          },
          'btnList': {
            type: 'collection',
            name: 'Botones',
            fields: sectionCardBtnsFields // Import this
          },
        }
      },
    },
    partsMap: [
      {
        'label': 'Bloque',
        'background': ['bgStyles'],
        'card': ['cardContainerStyles']
      },
      {
        'label': 'Items',
        'strings': ['items'],
        'card': ['itemCardStyles']
      },
      {
        'label': 'Imagen',
        'card': ['imgCardStyles']
      },
      {
        'label': 'Título',
        'text': ['titleStyles'],
        'card': ['titleCardStyles']
      },
      {
        'label': 'Subtítulo',
        'text': ['subtitleStyles'],
        'card': ['subtitleCardStyles']
      },
      {
        'label': 'Contenido',
        'strings': ['items'],
        'text': ['contentStyles'],
        'card': ['contentCardStyles']
      },
      {
        'label': 'Botones',
        'card': ['btnListStyles']
      },
    ]
  };
};

export function TextGridAExtended(props) {
  let {
    instance,
    items,
    bgStyles,
    itemCardStyles,
    titleStyles,
    titleCardStyles,
    subtitleStyles,
    subtitleCardStyles,
    contentTextCardStyles,
    contentTextStyles,
    contentCardStyles,
    contentStyles,
    cardContainerStyles,
    imgCardStyles,
    specDesign,
    history,
  } = props;

  return (
    <Background params={bgStyles} classes={{ bgContainer: "snap-start" }} specDesign={specDesign}>
      <CardContainer params={cardContainerStyles} specDesign={specDesign}>
        <div className="flex flex-col gap-24 lg:gap-32 2xl:gap-44">
          {items?.map(({ img, title, subtitle, content, contentText, btnList, btnListStyles }, index) => (
            <Card key={index} params={itemCardStyles} classes={{ cardContainer: "space-y-4" }} specDesign={specDesign}>
              {/* Imagen */}
              {img ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
                  <Image imageParams={img} cardParams={imgCardStyles} specDesign={specDesign} instance={instance} />
                </AnimationAddClassWhenVisible>
              ) : null}
              {/* Titulo */}
              {title ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
                  <Text textParams={titleStyles} cardParams={titleCardStyles} classDefault="text-black font-brand-main font-bold text-2xl lg:text-4xl xl:text-5xl mb-2" specDesign={specDesign}>
                    {lineBreaks(compileStringTemplate(title, specDesign.stringsVars))}
                  </Text>
                </AnimationAddClassWhenVisible>
              ) : null}
              {/* Subtitulo */}
              {subtitle ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
                  <Text textParams={subtitleStyles} cardParams={subtitleCardStyles} classDefault="text-black font-brand-main text-xl lg:text-2xl mb-2" specDesign={specDesign}>
                    {lineBreaks(compileStringTemplate(subtitle, specDesign.stringsVars))}
                  </Text>
                </AnimationAddClassWhenVisible>
              ) : null}
              {/* Contenido textArea */}
              {contentText ? (
                <Card params={contentTextCardStyles} specDesign={specDesign}>
                  <Text textParams={contentTextStyles} classDefault="text-black text-center 2xl:text-lg" specDesign={specDesign}>
                    {lineBreaks(contentText)}
                  </Text>
                </Card>
              ) : null}
              {/* Contenido notion */}
              {content ? (
                <NotionShowLazy value={content} render={(children) => (
                  <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                    <Card params={contentCardStyles} specDesign={specDesign}>
                      <Text textParams={contentStyles} specDesign={specDesign}>
                        {children}
                      </Text>
                    </Card>
                  </AnimationAddClassWhenVisible>
                )} />
              ) : null}
              {btnList?.length ? (
                <Card params={btnListStyles} specDesign={specDesign}>
                  <BtnsList items={btnList} specDesign={specDesign} history={history} instance={instance} />
                </Card>
              ) : null}
            </Card>
          ))}
        </div>
      </CardContainer>
    </Background>
  );
}

const presets = {
  'base': {
    "bgStyles": [
      {
        "bgColor": "#ffffff",
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    "cardContainerStyles": [
      {
        "screen": "xxs",
        "marginX": 4,
        "marginY": 24
      },
      {
        "screen": "md",
        "marginX": 12,
        "marginY": 32
      },
      {
        "padding": 40,
        "screen": "lg",
        "marginY": 40
      },
      {
        "screen": "xl",
        "marginX": 64,
        "marginY": 40
      },
      {
        "screen": "2xl",
        "marginX": 72,
        "marginY": 56
      }
    ],
    "titleStyles": [
      {
        "textSize": "3xl",
        "fontVariations": [
          "bold"
        ],
        "textAlign": "center",
        "screen": "xxs",
        "textColor": "#000000",
        "font": "main"
      },
      {
        "screen": "md",
        "textAlign": "left",
      },
      {
        "textSize": "4xl",
        "screen": "lg"
      },
      {
        "textSize": "5xl",
        "screen": "xl"
      }
    ],
    "subtitleStyles": [
      {
        "textSize": "2xl",
        "textAlign": "center",
        "screen": "xxs",
        "textColor": "#000000",
        "font": "secondary"
      },
      {
        "screen": "md",
        "textAlign": "left",
      },
      {
        "textSize": "3xl",
        "screen": "xl"
      },
      {
        "textSize": "4xl",
        "screen": "2xl"
      },
    ],
  }
};
import _ from 'lodash';
import dayjs from 'dayjs';
import Model from '../../libs/ModelClass';

import { FilterRenderText, FilterCreatorText } from './FilterText';
import { FilterRenderDate, FilterCreatorDate } from './FilterDate';
import { FilterRenderNumber, FilterCreatorNumber, singleOperatorOptions } from './FilterNumber';
import { FilterRenderBoolean, FilterCreatorBoolean } from './FilterBoolean';
import { FilterRenderSelect, FilterCreatorSelect } from './FilterSelect';
import { FilterRenderMultiSelect, FilterCreatorMultiSelect } from './FilterMultiSelect';
import { FilterRenderSelectOneEntityDocument, FilterCreatorSelectOneEntityDocument } from './FilterSelectOneEntityDocument';
import { FilterRenderSelectManyEntityDocument, FilterCreatorSelectManyEntityDocument } from './FilterSelectManyEntityDocument';
import { FilterRenderGPS, FilterCreatorGPS } from './FilterGPS';
import { FilterRenderSelectCity, FilterCreatorSelectCity } from './FilterSelectCity';
import MenuStyleCollapsibleDrawer from "./MenuStyleCollapsibleDrawer";
import MenuStyleCollapsible from "./MenuStyleCollapsible";
import MenuStyleCompact from "./MenuStyleCompact";
import FormFiltersRender from "./FormFiltersRender";
import SelectedValuesList from "./SelectedValuesList";

export {
  // Text
  FilterRenderText,
  FilterCreatorText,
  // Date
  FilterRenderDate,
  FilterCreatorDate,
  // Number
  FilterRenderNumber,
  FilterCreatorNumber,
  // Boolean
  FilterRenderBoolean,
  FilterCreatorBoolean,
  // Select
  FilterRenderSelect,
  FilterCreatorSelect,
  // Multiselect
  FilterRenderMultiSelect,
  FilterCreatorMultiSelect,
  // SelectOneEntityDocument
  FilterRenderSelectOneEntityDocument,
  FilterCreatorSelectOneEntityDocument,
  // SelectManyEntityDocument
  FilterRenderSelectManyEntityDocument,
  FilterCreatorSelectManyEntityDocument,
  // GPS
  FilterRenderGPS,
  FilterCreatorGPS,
  // SelectCity
  FilterRenderSelectCity,
  FilterCreatorSelectCity,
  // no aplica 
  // TextArea
  // Gallery
    // TODO agregar algún tipo de filtro para TextArea y Gallery
  
  // Menu Styles
  MenuStyleCollapsibleDrawer,
  MenuStyleCollapsible,
  MenuStyleCompact,
  // Menu Parts
  FormFiltersRender,
  SelectedValuesList
};

// SelectedValuesList
export const getSelectedValueString = async (props) => {
  let {
    filterValue,
    filter,
    taxonomyType
  } = props;

  const concatTrueMap = () => {
    let options = taxonomyType?.param?.options;
    if (props?.paramsByFieldSlug && props.paramsByFieldSlug[taxonomyType.nameSlug]?.options) {
      options = props.paramsByFieldSlug[taxonomyType.nameSlug].options;
    }
    if (options && filterValue) {
      if (_.isObject(filterValue)) {
        const selectedOptions = options.filter((opt) => filterValue[opt.value]);
        if (_.size(selectedOptions)) {
          return _.map(selectedOptions, 'label').join(', ');
        }
      }
      else if (_.isString(filterValue)) {
        const selectedOptions = options.filter((opt) => opt.value === filterValue);
        if (_.size(selectedOptions)) {
          return selectedOptions[0].label;
        }
      }
    }
  }

  ////////////////////////////////
  // DataTypes
  ////////////////////////////////
  if (taxonomyType?.type === 'gps') {
    if (taxonomyType.filter?.mode === 'placeSelect') {
      return filterValue?.formatted_address + ' - ' + filterValue?.distance + ' km';
    } 
    else if (taxonomyType.filter?.mode === 'gpsExact') {
      return 'Alcance ' + filterValue?.distance + ' km';
    }
  }

  if (taxonomyType?.type === 'select') {
    if (taxonomyType.filter?.mode === 'multiselect') {
      return concatTrueMap();
    }
    if (taxonomyType?.param?.options) {
      const option = taxonomyType?.param?.options?.find((opt) => opt.value === filterValue);
      if (option) {
        return option.label;
      }
    }
  }

  if (taxonomyType?.type === 'multiselect') {
    if (!filterValue) {
      return null;
    }
    return concatTrueMap();
  }

  if (taxonomyType?.type === 'selectOneEntityDocument' && filterValue) {
    if (taxonomyType?.param?.entityNameSlug) {
      const ExtendedModel = Model.extend(taxonomyType?.param?.entityNameSlug);
      const doc = await ExtendedModel.findById(filterValue);
      return doc?.data?.name;
    }
  }
  
  // array values
  if (Array.isArray(filterValue)) {
    let optionLabels = [];
    filterValue.forEach((value) => {
      if (taxonomyType?.param?.options) {
        const option = taxonomyType?.param?.options?.find((opt) => opt.value === value);
        if (option) {
          optionLabels.push(option.label);
        }
      }
    });
    return optionLabels.join(', ');
  }

  ////////////////////////////////
  // Default filter modes
  ////////////////////////////////
  if (filter?.mode === 'simpleInput' && filterValue !== null) {
    let res;
    if (taxonomyType?.type === 'date') {
      res = dayjs(filterValue).utc().format('ll');
    }
    if (taxonomyType?.type === 'number' || taxonomyType?.type === 'numberId') {
      res = Number(filterValue);
    }
    return res;
  }
  
  if (filter?.mode === 'singleOperator') {
    let res = filterValue?.value;
    singleOperatorOptions.forEach((option) => {
      if (option.value === filterValue?.operator) {
        res = `${option.label} ${filterValue.value}`;
      }
    });
    return res;
  } 

  if (filter?.mode === 'singleRange' && (filterValue?.startValue || filterValue?.endValue)) {
    let res;
    if (taxonomyType?.type === 'date') {
      res = filterValue?.startValue ? 'Desde: ' + dayjs(filterValue?.startValue).utc().format('ll') : '';
      if (filterValue?.endValue) {
        res += filterValue?.startValue ? ', ' : ' ';
        res += ' Hasta: ' + dayjs(filterValue?.endValue).utc().format('ll');
      }
    }
    if (taxonomyType?.type === 'number') {
      res = filterValue?.startValue ? 'Desde: ' + Number(filterValue?.startValue) : '';
      if (filterValue?.endValue) {
        res += filterValue?.startValue ? ', ' : ' ';
        res += ' Hasta: ' + Number(filterValue?.endValue);
      }
    }
    return res;
  } 
  
  if (filter?.mode === 'rangeSelect' && filterValue?.rangeIndex >= 0) {
    const option = filter?.ranges[ parseInt(filterValue?.rangeIndex) ];
    return option?.rangeName;
  }

  return filterValue;
};

// used in filterDataToQueryFormatter
export const getSelectedQuery = (props) => {
  let {
    filterValue,
    filter,
    taxonomyType,
    valueFormatter
  } = props;
  
  /**
   * by filter type
   */
  
  if (taxonomyType?.type === 'select') {
    if (taxonomyType.filter?.mode === 'multiselect') {
      if (_.size(filterValue)) {
        return { in: _.map(filterValue, (trueValue, field) => field) };
      }
    }
  }

  if (taxonomyType?.type === 'multiselect') {
    let valueToUse = !_.isArray(filterValue) ? [filterValue] : filterValue;
    return { 'in-array': valueToUse };
  }

  /**
   * by filter modes
   */ 

  let selectedQuery = {};

  if (filter?.mode === "simpleInput" || !filter?.mode) {
    if (taxonomyType?.type === "date") {
      selectedQuery = {
        gte: dayjs(filterValue).utc().startOf('day').toISOString(),
        lte: dayjs(filterValue).utc().endOf('day').toISOString(),
      };
    }
    else if (taxonomyType?.type === "numberId") {
      selectedQuery = {
        equal: parseInt(filterValue)
      };
    } else {
      selectedQuery = filterValue;
    }
  } 

  else if (filter?.mode === "singleOperator") {
    selectedQuery[filterValue.operator] = filterValue.value;
  } 

  else if (filter?.mode === "singleRange") {
    if (filterValue.startValue) { selectedQuery.gte = valueFormatter(filterValue.startValue, taxonomyType); }
    if (filterValue.endValue) { selectedQuery.lte = valueFormatter(filterValue.endValue, taxonomyType); }
  } 

  else if (filter?.mode === "rangeSelect") {
    let range = filter.ranges?.find((range, index) => index === parseInt(filterValue.rangeIndex));
    if (range?.startValue && range?.startOperator) { selectedQuery[range?.startOperator] = valueFormatter(range?.startValue, taxonomyType); }
    if (range?.endValue && range?.endOperator) { selectedQuery[range?.endOperator] = valueFormatter(range?.endValue, taxonomyType); }
  }

  return selectedQuery;
};
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldCustom,
  FormFieldSelect,
  FormFieldSelectOneEntityDocument,
  RawInputSelectDocumentExtended
} from '../Form';
import {
  // Select
  FilterRenderSelectManyEntityDocument,
  FilterCreatorSelectManyEntityDocument,
} from '../Filters';
import { useModule } from '../../libs/ModuleContext';
import { useFetchInstanceDoc } from '../../modules/instance/InstanceSelector';
import { useEntityFullBySlug } from '../../modules/entity/Entity';
import { useAsyncMemo } from 'use-async-memo';
import { getJson } from '../../libs/utils';


export default {
  Render(props) {
    let { entitySlug, _fieldName, param, style, design } = processRenderProps(props);
    const { isAllowed } = useModule();
    // fetch instance and add to values to inherit filter
    const selectedInstance = useFetchInstanceDoc(props.instance);
    let valuesToUse = {...props.values};
    if (!valuesToUse.instanceId) {
      valuesToUse.instanceId = selectedInstance?.id;
    }

    if (
      !param.fromJson &&
      !isAllowed(entitySlug, ['list', 'instance:list'])
      || !valuesToUse.instanceId
    ) {
      return null;
    }
    
    if(design === "default") {
      return (
        <FormFieldCustom
          Render={RawInputSelectDocumentExtended}
          multiple={true}
          {...props}
          {...style}
          {...param}
          name={_fieldName}
          values={valuesToUse}
        />
      );
    }
  },

  RenderFilter(props) {
    return <FilterRenderSelectManyEntityDocument {...props} DataTypeRender={this.Render} isFilter={true} />
  },

  RenderFilterParam(params) {
    return <FilterCreatorSelectManyEntityDocument {...params} />
  },

  RenderFilterPreview({ values }) {
    return <FilterRenderSelectManyEntityDocument values={values} DataTypeRender={this.Render} />
  },

  RenderInputParams: ({ values, param, taxonomyTypeDoc, taxonomyTypesOfEntity }) => {
    const [ tab, setTab ] = useState(values?.param?.fromJson ? 'json' : 'entity');
    let normalizedFieldPrefix = _.replace(taxonomyTypeDoc?.data?.nameSlug, 'Id', '_');
    let inheritFilterOptions = [];
    
    taxonomyTypesOfEntity?.forEach((doc) => {
      if (
        (doc.id === taxonomyTypeDoc.id)
        || (!_.endsWith(doc.data.nameSlug, 'Id'))
      ) {
        return;
      }
      inheritFilterOptions.push({
        value: doc.data.nameSlug,
        label: `${doc.data.name} [ ${doc.data.nameSlug} ]`,
      });
    });

    const relatedEntitySpec = useEntityFullBySlug({
      entitySlug: values?.param?.entityNameSlug,
      includeFilters: false
    });

    const allFieldsOptions = useMemo(() => {
      let fields = [];
      fields.push({
        value: 'id',
        label: 'id'
      });
      
      relatedEntitySpec?.taxonomyTypesDocList?.map((taxonomyTypeDoc) => {
        fields.push({
          value: taxonomyTypeDoc.data.nameSlug,
          label: `${taxonomyTypeDoc.data.name} [ ${taxonomyTypeDoc.data.nameSlug} ]`,
        });

        if (
          taxonomyTypeDoc.data.type === 'selectManyEntityDocument'
          && taxonomyTypeDoc.data.param.normalizedFields?.length
        ) {
          let relatedNormalizedPrefix = _.replace(taxonomyTypeDoc.data.nameSlug, 'Id', '_');
          taxonomyTypeDoc.data.param.normalizedFields.map((remoteNormalizedField) => {
            fields.push({
              value: `${relatedNormalizedPrefix + remoteNormalizedField}`,
              label: `${relatedNormalizedPrefix + remoteNormalizedField} [ normalized from ${taxonomyTypeDoc.data.param.entityNameSlug} ]`,
            });
          });
        }
      });

      return fields;
    }, [relatedEntitySpec]);

    const jsonData = useAsyncMemo(async () => {
      if (values?.param?.fromJson) {
        return await getJson(values?.param?.fromJson);
      }
    }, [values?.param?.fromJson]);

    const jsonFieldsOptions = useMemo(() => {
      let fields = [];
      if (jsonData) {
        Object.keys(jsonData[0]).map((key) => {
          fields.push({
            value: key,
            label: key
          });
        });
      }
      return fields;
    }, [jsonData]);

    return (<>
      <IonSegment value={tab}>
        <IonSegmentButton value="entity" onClick={() => setTab('entity') }>
          <IonLabel>Entidad</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="json" onClick={() => setTab('json') }>
          <IonLabel>JSON</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <br />
      {tab === 'entity' ? (<>
        <FormFieldSelectOneEntityDocument
          name="param.entityNameSlug" // TODO migrate entitySlug to entityNameSlug
          entitySlug="entities"
          title="Entidad"
          optionsMapper={
            (docs) => 
              docs.map((option) => ({
                value: option?.data?.nameSlug,
                label: option?.data?.name,
              }))
          }
        />
        <FormFieldSelect
          name="param.inheritFilter"
          title="Heredar filtro según clave foránea"
          options={inheritFilterOptions}
          multiple={true}
        />
        <FormFieldSelect
          name="param.normalizedFields"
          title="Copiar datos remotos del documento seleccionado"
          helpText={`Se aplicarán con el prefijo: ${normalizedFieldPrefix}`}
          options={allFieldsOptions}
          multiple={true}
        />
      </>) : null}

      {/* <FormFieldSelect
        name="param.fieldsToSync"
        title="Copiar datos del documento seleccionado"
        options={}
        multiple={true}
      /> */}

      <FormFieldSelect
        name="style.design"
        title="Diseño"
        placeholder="Diseño"
        options={[
          { value: 'default', label: 'Por defecto' }
        ]}
      />
    </>);
  },
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderShowParams: ({ values }) => (null),
  
  RenderInputPreview: ({ values, formFieldProps }) => (null)
}
import React, { useState } from "react";
import {
  IonButton,
  IonIcon,
  IonModal
} from "@ionic/react";
import { createOutline } from "ionicons/icons";
import CanvasCreatorLazy from "./CanvasCreatorLazy";


export const RawInputCanvasToPrint = ({ value, onChange }) => {
  const [showModal, setShowModal] = useState(false);

  const handleApply = (newDesign) => {
    onChange(newDesign);
    setShowModal(false);
  };

  return (<>
    <IonButton 
      onClick={() => setShowModal(true)}
      color="primary"
      fill="outline"
      size="small"
    >
      <IonIcon icon={createOutline} slot="start" />
      Editar Documento
    </IonButton>

    <IonModal
      isOpen={showModal}
      onDidDismiss={() => setShowModal(false)}
      backdropDismiss={false}
      className="wide"
    >
      <CanvasCreatorLazy
        title="Diseño para impresión"
        value={value}
        onChange={handleApply}
        onClose={() => setShowModal(false)}
      />
    </IonModal>
  </>);
};

const dataTypeCanvasToPrint = {
  RenderForm: RawInputCanvasToPrint
};

export default dataTypeCanvasToPrint;